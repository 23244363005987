import React from 'react';
import { Card } from 'antd';

const Users = () => {
  return (
    <Card>
      <h2>Users</h2>
      <p>User Management Section</p>
    </Card>
  );
};

export default Users;
