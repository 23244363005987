import React from 'react';
import { Card } from 'antd';

const Help = () => {
  return (
    <Card>
      <h2>Help</h2>
      <p>Help Section</p>
    </Card>
  );
};

export default Help;
